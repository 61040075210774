import Vue from "vue";
import Vuex from "vuex";

import { alert } from "./alertModule";
import { authentication } from "./authenticationModule";
import { settings } from "./settingsModule";
import { licenseValid } from "./licenseValidModule";
import { license } from "./licenseModule";
import { branding } from "./brandingModule";
import { plodtrackSync } from "../plodtrack/store/plodtrackSyncModule";
import { minnovareSync } from "../minnovare/store/minnovareSyncModule";
import { apiSync } from "../apisync/store/apiSyncModule";
import { shiftReview } from "./shiftReviewModule";
import { equipmentReview } from "./equipmentReviewModule";
import { shiftAllocation } from "./shiftAllocationModule";
import { modules } from "./modulesModule";
import { help } from "./helpModule";
import { navigation } from "./navigationModule";
import { schema } from "./schemaModule";
import { theme } from "./themeModule";
import { entityHub } from "./entityHubModule";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        navigation,
        alert,
        authentication,
        settings,
        licenseValid,
        license,
        branding,
        plodtrackSync,
        minnovareSync,
        apiSync,
        shiftReview,
        equipmentReview,
        shiftAllocation,
        modules,
        help,
        schema,
        theme,
        entityHub,
    },
    state: {
        isTouchDevice: false,
    },
    mutations: {
        setIsTouchDevice(state, isTouchDevice) {
            state.isTouchDevice = isTouchDevice;
        },
    },
    getters: {
        getHelpLink: (state) => (key) => {
            return state.help.helpLinks[key];
        },
    },
});

export default store;
