<template>
    <div v-if="isVisible" class="snowfall-container" :class="{ 'fade-out': isFading }">
        <div
            v-for="snowflake in snowflakes"
            :key="snowflake.id"
            class="snowflake"
            :style="{
                left: `${snowflake.x}%`,
                animationDuration: `${snowflake.duration}s`,
                animationDelay: `${snowflake.delay}s`,
                opacity: snowflake.opacity,
            }"
        ></div>
    </div>
</template>

<script>
    export default {
        name: "Snowfall",
        data() {
            return {
                snowflakes: [],
                numberOfSnowflakes: 50,
                isVisible: true,
                isFading: false,
            };
        },
        mounted() {
            this.start();
            this.clean();
        },
        methods: {
            start() {
                for (let i = 0; i < this.numberOfSnowflakes; i++) {
                    this.snowflakes.push({
                        id: i,
                        x: Math.random() * 100,
                        duration: 3 + Math.random() * 7,
                        delay: Math.random() * 5,
                        opacity: 0.4 + Math.random() * 0.6,
                    });
                }
            },
            clean() {
                setTimeout(() => {
                    this.isFading = true;

                    setTimeout(() => {
                        this.isVisible = false;
                    }, 1000);
                }, 5000);
            },
        },
    };
</script>

<style scoped>
    .snowfall-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 1000;
        opacity: 1;
        transition: opacity var(--fade-duration) ease-out;
    }

    .snowfall-container.fade-out {
        opacity: 0;
    }

    .snowflake {
        position: absolute;
        top: -10px;
        width: 10px;
        height: 10px;
        background: white;
        border-radius: 50%;
        animation: snowfall linear infinite;
        filter: blur(1px);
    }

    @keyframes snowfall {
        0% {
            transform: translateY(-10px) rotate(0deg);
        }
        100% {
            transform: translateY(100vh) rotate(360deg);
        }
    }
</style>
