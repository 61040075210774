import { DateTime } from "luxon";
import themeEnum from "@/enums/themeEnum";

export const themePeriods = [
    {
        theme: themeEnum.Christmas,
        getDateRange: (year) => {
            const christmas = DateTime.local(year, 12, 25);
            const start = christmas.minus({ days: 7 });
            const end = christmas.plus({ days: 7 });
            return { start, end };
        },
    },
];

export const themeHelper = {
    getTheme(date = DateTime.now()) {
        const year = date.year;

        for (const period of themePeriods) {
            const { start, end } = period.getDateRange(year);

            if (date >= start && date <= end) {
                return period.theme;
            }
        }

        return themeEnum.Default;
    },
};
