<template>
    <v-card class="pa-4 rounded-lg elevation-4 d-flex flex-column" :style="gradientStyle">
        <div v-if="title" class="d-flex flex-shrink-1 text-h6 text-uppercase">{{ title }}</div>
        <div class="d-flex flex-grow-1 justify-center align-center"><slot></slot></div>
    </v-card>
</template>

<script>
    export default {
        name: "AppDashboardCard",
        props: {
            title: {
                type: String,
                required: false,
            },
            color: {
                type: String,
                default: "primary", // Default color
            },
        },
        computed: {
            gradientStyle() {
                // Generate a gradient using Vuetify theme colors
                const baseColor = `var(--v-${this.color}-base)`;
                const lighterColor = `var(--v-${this.color}-lighten1)`;
                return {
                    background: `linear-gradient(to right, ${baseColor}, ${lighterColor})`,
                    color: "white", // Ensure text is visible
                };
            },
        },
    };
</script>
