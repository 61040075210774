import { themeHelper } from "@/helpers/themeHelper";
import webThemeEnum from "@/enums/webThemeEnum";
import themeEnum from "@/enums/themeEnum";

export const theme = {
    namespaced: true,
    state: {
        currentTheme: null,
    },

    actions: {
        updateTheme({ commit, rootState }) {
            const webThemeSetting = rootState.settings.webTheme;

            if (webThemeSetting === webThemeEnum.Automatic) {
                const theme = themeHelper.getTheme();
                commit("setTheme", theme);
            } else {
                commit("setTheme", themeEnum.Default);
            }
        },
    },

    mutations: {
        setTheme(state, theme) {
            state.currentTheme = theme;
        },
    },

    getters: {
        currentTheme: (state) => state.currentTheme,
        isThemeActive: (state) => (themeId) => state.currentTheme === themeId,
    },
};
