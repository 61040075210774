import { connectToHub, closeHubConnection } from "../services/entityHub";

function getDefaultState() {
    return {
        connection: null,
    };
}

const state = getDefaultState;

const actions = {
    async load({ commit }) {
        const connection = await connectToHub();
        commit("connection", connection);
    },

    clear({ commit, state }) {
        closeHubConnection(state.connection);
        commit("clear");
    },
};

const mutations = {
    connection(state, connection) {
        state.connection = connection;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    },
};

export const entityHub = {
    namespaced: true,
    state,
    actions,
    mutations,
};
